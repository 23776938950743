import { ACCESS_TOKEN } from "../../../constants";
import { IEntity } from "../../../models/auth";
import { LOG_OUT, SET_USER_DATA } from "../../actionTypes";

const initialState: IEntity.AuthState = {
  isAuth: false,
  userData: null,
};

export const authReducer = (
  state: IEntity.AuthState = initialState,
  action: IEntity.AuthAction
) => {
  switch (action.type) {
    case SET_USER_DATA: {
      return {
        isAuth: action.payload.isAuth,
        userData: action.payload.userData,
      };
    }
    case LOG_OUT: {
      localStorage.removeItem(ACCESS_TOKEN);
      return {
        isAuth: false,
        userData: null,
      };
    }

    default:
      return { ...state };
  }
};
