import { IRoute } from "../models/route";
import { routes } from "./Routes";

export const getPublicRoutes = () => {
  return routes.filter((item: IRoute.Routes) => !item.config.isAuth);
};

export const getPrivateRoutes = () => {
  return routes.filter((item: IRoute.Routes) => item.config.isAuth);
};
