import { IRoute } from "../models/route";
import Landing from "../pages/Landing";
import Login from "../pages/auth/login";
import Products from "../pages/products";

export const routes: IRoute.Routes[] = [
  {
    name: "Landing",
    path: "/",
    component: Landing,
    config: {
      isAuth: false,
    },
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
    config: {
      isAuth: false,
    },
  },
  {
    name: "Products",
    path: "/",
    component: Products,
    config: {
      isAuth: true,
    },
  },
];
