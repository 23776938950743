import { Button, Form, Input, message } from "antd";
import { AxiosError } from "axios";
import AuthService from "../../../services/auth";
import { useDispatch } from "react-redux";
import { SET_USER_DATA } from "../../../redux/actionTypes";
import { ACCESS_TOKEN } from "../../../constants";
import { useState } from "react";
import { IEntity } from "../../../models/auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: IEntity.UserType) => {
    try {
      setLoading(true);
      const res = await AuthService.userLogin(values);
      navigate("/");
      localStorage.setItem(ACCESS_TOKEN, res.data.token);

      dispatch({
        type: SET_USER_DATA,
        payload: {
          isAuth: true,
          userData: res.data,
        },
      });
      message.success("Login success");
    } catch (e: unknown) {
      const error = e as AxiosError;
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="flex justify-center items-center h-[100vh] w-full">
      <Form
        name="basic"
        style={{ width: 400 }}
        // initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className="w-full"
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
