import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ACCESS_TOKEN } from "./constants";
import Layout from "./layout";

import { SET_USER_DATA } from "./redux/actionTypes";

import AuthService from "./services/auth";
import { getPrivateRoutes, getPublicRoutes } from "./route/config";
import CustomComponent from "./layout/CustomComponent";
import PageNotFound from "./layout/NotFound";
import Loader from "./layout/Loader";
import { IEntity } from "./models/auth";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuth } = useSelector(
    (state: IEntity.RootState) => state.authReducer
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem(ACCESS_TOKEN);

      // Token Expiration Check
      if (token) {
        try {
          let resp = await AuthService.aboutMe();
          dispatch({
            type: SET_USER_DATA,
            payload: { isAuth: true, userData: resp.data },
          });
        } catch (error) {
          navigate(`/`);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        navigate(`/`);
      }
    })();
  }, []);

  return loading ? (
    <div className="w-[100%] h-[100vh] flex justify-center items-center">
      <Loader />
    </div>
  ) : (
    <Routes>
      {isAuth ? (
        <Route path="/" element={<Layout />}>
          {getPrivateRoutes().map((item, i) => (
            <Route
              key={i}
              path={`${item.path}`}
              element={<CustomComponent Component={item.component} />}
            />
          ))}
        </Route>
      ) : (
        getPublicRoutes().map((item, i) => (
          <Route
            key={i}
            path={`${item.path}`}
            element={<CustomComponent Component={item.component} />}
          />
        ))
      )}

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
