import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Button, Layout as LayoutAntd, Menu, theme } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { LOG_OUT } from "../redux/actionTypes";
import { IEntity } from "../models/auth";

const { Header, Sider, Content } = LayoutAntd;

const Layout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [togleUsrInfo, setTogleUsrInfo] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { userData } = useSelector(
    (state: IEntity.RootState) => state.authReducer
  );

  const items = [
    {
      key: "1",
      icon: <VideoCameraOutlined />,
      label: "Products",
      onClick: () => navigate("/"),
    },
    {
      key: "2",
      icon: <UserOutlined />,
      label: "Users",
      onClick: () => navigate("/users"),
    },
    {
      key: "3",
      icon: <UploadOutlined />,
      label: "Posts",
      onClick: () => navigate("/posts"),
    },
    {
      key: "4",
      icon: <UploadOutlined />,
      label: "Todos",
      onClick: () => navigate("/todos"),
    },
  ];

  useEffect(() => {}, [togleUsrInfo]);

  const signOut = () => {
    dispatch({
      type: LOG_OUT,
    });
    navigate("/");
  };

  return (
    <LayoutAntd className="h-[100vh]">
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical">
          <h4 className="h4 text-white my-4 text-center">UNICAL</h4>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={items}
        />
      </Sider>
      <LayoutAntd>
        <Header
          style={{ padding: 0, background: colorBgContainer }}
          className="flex items-center justify-between"
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
          {userData ? (
            <div className="relative flex items-center mr-4">
              <img
                onClick={() => setTogleUsrInfo((prev) => !prev)}
                className="w-10 h-10 rounded-full cursor-pointer"
                src={"https://picsum.photos/id/237/200/300"}
                alt={userData.username}
              />

              {togleUsrInfo ? (
                <div className="top-[50px] left-[-140px] absolute z-10  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                  <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
                    <div className="font-medium truncate">
                      {userData.username}
                    </div>
                  </div>

                  <div className="py-1">
                    <span
                      onClick={signOut}
                      className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                    >
                      Sign out
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </Header>
        <Content
          className="overflow-x-auto"
          style={{
            margin: "24px 16px",
            padding: 24,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
        </Content>
      </LayoutAntd>
    </LayoutAntd>
  );
};

export default Layout;
